<template>
  <vca-column>
    <div style="white-space: pre; display: none">
      {{ getJSON }}
    </div>
    <vca-card v-if="myValue.creator" class="shadowed">
      <EventMeta v-model="myValue" />
    </vca-card>
    <vca-card class="shadowed">
      <General v-model="myValue" ref="general" />
    </vca-card>
    <vca-card class="shadowed">
      <Times v-model="myValue" ref="times" />
      <EventApplicationSettings
        v-if="myValue.type_of_event != 'crew_meeting'"
        v-model="myValue"
        ref="application"
      />
    </vca-card>
    <vca-card
      v-if="hasSystemPermission() && myValue.type_of_event == 'tour'"
      class="shadowed"
    >
      <TourEventCrewSelect v-model="myValue" ref="event_crews" />
      <TourCrews v-model="myValue" ref="crews" />
    </vca-card>
    <vca-card
      v-if="hasSystemPermission() && myValue.type_of_event != 'tour'"
      class="shadowed"
    >
      <EventCrewSelect v-model="myValue" ref="event_crews" />
      <Crews v-model="myValue" ref="crews" />
      <OrganisationSelect
        v-if="hasSystemPermission() && myValue.crew_id == ''"
        v-model="myValue"
        ref="organisation"
      />
    </vca-card>
    <vca-row>
      <vca-card v-if="hasSystemPermission()" class="shadowed">
        <InternalAsp v-model="myValue" ref="internal_asp" />
      </vca-card>
      <vca-card
        v-else-if="value.type_of_event != 'crew_meeting'"
        class="shadowed"
      >
        <InternalAspDisplay v-model="myValue" />
      </vca-card>
      <vca-card v-if="value.type_of_event != 'crew_meeting'" class="shadowed">
        <ExternalAsp v-model="myValue" ref="external_asp" />
      </vca-card>
    </vca-row>
    <vca-card v-if="!hasSystemPermission()" class="shadowed">
      <EventAsp v-model="myValue" ref="event_asp" />
    </vca-card>
    <vca-card class="shadowed" v-if="value.type_of_event != 'crew_meeting'">
      <Tools v-model="myValue" />
    </vca-card>
  </vca-column>
</template>

<script>
import Crews from "@/components/events/form/Crews";
import EventApplicationSettings from "@/components/events/form/EventApplicationSettings";
import EventAsp from "@/components/events/form/EventAsp";
import EventCrewSelect from "@/components/events/form/EventCrewSelect";
import EventMeta from "@/components/events/form/EventMeta";
import ExternalAsp from "@/components/events/form/ExternalAsp";
import General from "@/components/events/form/General";
import InternalAsp from "@/components/events/form/InternalAsp";
import InternalAspDisplay from "@/components/events/form/InternalAspDisplay";
import Times from "@/components/events/form/Times";
import Tools from "@/components/events/form/Tools";
import TourCrews from "@/components/events/form/tour/Crews";
import TourEventCrewSelect from "@/components/events/form/tour/EventCrewSelect";
import { mapGetters } from "vuex";
import OrganisationSelect from "./OrganisationSelect.vue";
export default {
  name: "EventForm",
  components: {
    EventMeta,
    General,
    Times,
    OrganisationSelect,
    EventApplicationSettings,
    TourCrews,
    Crews,
    EventCrewSelect,
    TourEventCrewSelect,
    EventAsp,
    InternalAspDisplay,
    InternalAsp,
    ExternalAsp,
    Tools,
  },
  props: {
    value: {
      type: Object,
      default: null,
    },
  },
  methods: {
    validate_type() {
      this.$refs.general.validate_type();
    },
    validate() {
      this.$refs.general.validate();
      if (this.hasSystemPermission()) {
        this.$refs.event_crews.validate();
        this.$refs.internal_asp.validate();
        this.$refs.crews.validate();
      }
      if (this.hasPoolPermission() && !this.hasSystemPermission()) {
        this.$refs.event_asp.validate();
        this.$refs.times.validate();
        if (this.value.type_of_event != "crew_meeting") {
          this.$refs.application.validate();
          this.$refs.application.checkDate(this.myValue.end_at);
        }
      }
    },
  },
  computed: {
    myValue: {
      set(value) {
        this.$emit("input", value);
      },
      get() {
        return this.value;
      },
    },
    ...mapGetters({
      user: "user/current",
      hasPoolPermission: "user/roles/hasPoolPermission",
      hasSystemPermission: "user/roles/hasSystemPermission",
    }),
    getJSON() {
      return JSON.stringify(this.value, null, 4); // Indented 4 spaces
    },
  },
};
</script>
<style lang="scss">
.event-form-error {
  color: $orange;
}
</style>
